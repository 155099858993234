
import AcModal from '@ui-kit/components/AcModal.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import { defineComponent, computed, ref } from 'vue'
import { getUrlWithAuthParams } from '@web/common/Utils'
import { currencyInfo } from '@ui-kit/util'
import { useRoute } from 'vue-router'
import urlParse from 'url-parse'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'

export default defineComponent({
  name: 'CatalogItem',
  components: {
    AcButton,
    AcModal,
    AsPreloader
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const currency = computed(() => currencyInfo().getCurrency(props.item.price.currencyCode)?.symbol || '¤')
    const isShowModal = ref(false)
    const modalTitle = ref<string | null>(null)
    const modalText = ref<string | null>(null)
    const modalIcon = ref('success')

    const isFree = computed(() => props.item.isFree)

    async function goToMore () {
      if (isFree.value) {
        isShowModal.value = true
        try {
          await store.dispatch('catalog/addToSubscription', props.item.id)
          modalTitle.value = i18n.global.t('courseSuccessfullyAdded')
          modalText.value = i18n.global.t('courseAppearInSection', { section: i18n.global.t('myCourses') })
          modalIcon.value = 'success'
        } catch (e) {
          modalTitle.value = i18n.global.t('error.error')
          modalText.value = null
          modalIcon.value = 'error'
          sentry.captureException(e)
          Logger.error(e)
        }
        return
      }
      const queryParams = {
        utm_source: 'lk',
        utm_medium: 'catalog'
      }
      if (route.name === 'home') {
        queryParams.utm_medium = 'catalog_main'
      }
      const urlInstance = urlParse(props.item.link, true)
      urlInstance.set('query', {
        ...urlInstance.query,
        ...queryParams
      })

      window.open(getUrlWithAuthParams(urlInstance.toString()), '_system')
    }

    function onHandlerClick () {
      isShowModal.value = false
      store.commit('catalog/removeItem', props.item.id)
    }

    return {
      currency,
      goToMore,
      isFree,
      isShowModal,
      modalTitle,
      modalText,
      modalIcon,
      onHandlerClick
    }
  }
})
